<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div class="d-flex align-items-center">
            <b-form-group label="Order Date From" class="d-flex">
              <flat-pickr
                v-model="date_start"
                :config="{
                  dateFormat: 'Y-m-d',
                  maxDate: date_end,
                }"
                class="form-control font-small-3"
                placeholder="Choose Date"
                readonly="readonly"
              />
            </b-form-group>
            <h5 class="mx-1 mt-1 d-flex align-items-center">to</h5>
            <b-form-group label="Order Date To" class="d-flex">
              <flat-pickr
                v-model="date_end"
                :config="{
                  dateFormat: 'Y-m-d',
                  minDate: date_start,
                }"
                class="form-control font-small-3"
                placeholder="Choose Date"
                readonly="readonly"
              />
            </b-form-group>
            <v-select
              v-model="selected_status_type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusTypeOptions"
              :clearable="true"
              @input="statusChange()"
              class="w-25 mt-1 ml-1"
              :reduce="(statusTypeOptions) => statusTypeOptions.value"
              placeholder="Choose Report"
              label="label"
            >
              <template #option="option">
                <span>{{ option.label }}</span>
              </template>
            </v-select>
            <b-button
              @click="getOrders()"
              variant="primary"
              class="btn-tour-skip mx-1 mt-1"
            >
              <span class="mr-25 align-middle">Get Orders</span>
            </b-button>
            <b-button
              @click="ExportToExcel()"
              variant="outline-primary"
              class="btn-tour-skip mt-1"
            >
              <span class="mr-25 align-middle">Extract Orders</span>
            </b-button>
          </div>
          <h1>{{ selected_status_type }}</h1>
          <div
            class="d-flex"
            v-if="selected_status_type === `Profit & Loss Report`"
          >
            <b-form-group label="Total Gross Profit" class="d-flex mt-1 mx-1">
              <p
                class="form-control font-small-3 d-flex align-items-center px-3 border-success"
              >
                {{ $helpers.formatTotal(total_gross_profit) }}
              </p>
            </b-form-group>
            <b-form-group label="Commission" class="d-flex mt-1">
              <p
                class="form-control font-small-3 d-flex align-items-center px-3 border-success"
              >
                {{ $helpers.formatTotal(total_commission) }}
              </p>
            </b-form-group>
          </div>
          <div
            class="d-flex"
            v-if="selected_status_type === `Convert Risk Report`"
          >
            <b-form-group label="Total Convert Risk" class="d-flex mt-1 mx-1">
              <p
                class="form-control font-small-3 d-flex align-items-center px-3 border-success"
              >
                {{ $helpers.formatTotal(total_convert_risk) }}
              </p>
            </b-form-group>
          </div>
          <MyTable :columns="columns" :rows="rows" :isLoading="isLoading">
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToOrderSummary(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'pickup'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.delivery_date
                    ? ""
                    : props.row.delivery_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'order_placed_date'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.order_placed_date
                    ? ""
                    : props.row.order_placed_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'delivery'"
                class="d-flex justify-content-center"
              >
                {{
                  !props.row.delivery_date
                    ? ""
                    : props.row.delivery_date.substr(0, 10)
                }}
              </div>
              <div
                v-if="props.column.field === 'costing'"
                class="d-flex justify-content-center"
              >
                RM
                {{ props.row.unit_price_cost ? props.row.unit_price_cost : 0 }}
              </div>
              <div
                v-if="props.column.field === 'profit'"
                class="d-flex justify-content-center"
              >
                RM {{ props.row.profit ? props.row.profit : 0 }}
              </div>
              <div
                v-if="props.column.field === 'commission'"
                class="d-flex justify-content-center"
              >
                RM {{ props.row.commission ? props.row.commission : 0 }}
              </div>
              <div
                v-if="props.column.field === 'sendername'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.first_name }}
              </div>

              <div
                v-if="props.column.field === 'recipientname'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.first_name }}
              </div>
              <div
                v-if="props.column.field === 'origin'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.complete_address }}
              </div>
              <div
                v-if="props.column.field === 'origin_postcode'"
                class="d-flex justify-content-center"
              >
                {{ props.row.sender.address_postcode }}
              </div>
              <div
                v-if="props.column.field === 'destination'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.complete_address }}
              </div>
              <div
                v-if="props.column.field === 'destination_postcode'"
                class="d-flex justify-content-center"
              >
                {{ props.row.recipient.address_postcode }}
              </div>
              <span v-if="props.column.field === 'status'">
                <b-button
                  v-if="props.row.status === 'Active'"
                  block
                  variant="light-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Assigned'"
                  block
                  variant="dark-purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Warehouse'"
                  block
                  variant="light-blue"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'In Transit'"
                  block
                  variant="yellow"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Out For Delivery'"
                  block
                  variant="purple"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Delivered'"
                  block
                  variant="turquoise"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button
                  v-else-if="props.row.status === 'Close Store'"
                  block
                  variant="maroon"
                >
                  {{ props.row.status }}
                </b-button>
                <b-button v-else block variant="orange">
                  {{ props.row.status }}
                </b-button>
              </span>
              <div
                v-if="props.column.field === 'quantity'"
                class="d-flex justify-content-center align-items-center"
              >
                {{ !props.row.quantity ? "" : props.row.quantity }}
              </div>
              <div
                v-if="props.column.field === 'selling_price'"
                class="d-flex justify-content-center"
              >
                RM {{ props.row.amount ? props.row.amount : 0 }}
              </div>

              <div
                v-if="props.column.field === 'convert_risk'"
                class="d-flex justify-content-center"
              >
                RM {{ props.row.convert_risk ? props.row.convert_risk : 0 }}
              </div>

              <div v-else-if="props.column.field === 'actions'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="25"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="handleModalOpen(props.row)">
                    <feather-icon icon="Edit2Icon" />
                    Edit Costings
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <!-- EDIT PRICE MODAL -->
    <b-modal
      ref="edit"
      id="edit"
      title="Edit Costing"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver ref="form">
        <form @submit.prevent="handleEditSubmit">
          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price_cost"
                name="Selling Price Cost"
                rules="required"
              >
                <b-form-group label="Selling Price Cost">
                  <b-form-input v-model="costingForm.selling_price_cost" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="convert_risk"
                name="Convert Risk"
                rules="required"
              >
                <b-form-group label="Convert Risk">
                  <b-form-input
                    v-model="costingForm.convert_risk"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-center my-1">
            <b-button type="submit" variant="primary"> Submit </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  created() {
    this.statusChange();
    this.getOrders();
  },

  methods: {
    handleModalOpen(order) {
      this.selectedOrder = order;
      this.costingId = this.selectedOrder.id;
      this.costingForm.selling_price_cost = this.selectedOrder.unit_price_cost;
      this.costingForm.double_charge_cost =
        this.selectedOrder.double_charge_cost;
      this.costingForm.convert_risk = this.selectedOrder.convert_risk;
      this.$refs.edit.show();
    },
    getOrders() {
      this.isLoading = true;
      this.$http
        .get(
          `get_report_by_type?order_type=Distributor&selected_status=${this.selected_status_type}&from_delivery_date=${this.date_start}&to_delivery_date=${this.date_end}`
        )
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows = response.data.data.filter(
              (row) => row.status !== "Payment Failed"
            );
            this.isLoading = false;
          } else if (response.data.message === "No Orders Found.") {
            this.rows = [];
            this.isLoading = false;
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.isLoading = false;
          }
        });

      if (this.selected_status_type === "Profit & Loss Report") {
        this.$http
          .get(
            `get_profit_and_loss_report/${this.date_start}/${this.date_end}/Distributor`
          )
          .then((response) => {
            this.total_commission = response.data.data.total_commission;
            this.total_gross_profit = response.data.data.total_gross_profit;
          });
      }

      if (this.selected_status_type === "Convert Risk Report") {
        this.$http
          .get(
            `get_total_convert_report/${this.date_start}/${this.date_end}/Distributor`
          )
          .then((response) => {
            this.total_convert_risk = response.data.data.total_convert_risk;
          });
      }
    },

    goToOrderSummary(id) {
      this.$router.push({
        name: "order-summary",
        params: {
          id: id,
        },
      });
    },

    statusChange() {
      if (this.selected_status_type === "Profit & Loss Report") {
        this.columns = this.profitloss;
      } else if (this.selected_status_type === "Convert Risk Report") {
        this.columns = this.convertrisk;
      } else {
        this.columns = this.report;
      }
      this.getOrders();
    },

    async ExportToExcel() {
      this.getOrders();
      const response = await this.$http.get(
        `export_report/${this.selected_status_type}/${this.date_start}/${this.date_end}/distributor`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `DistributorReports_${this.date_start}-${this.date_end}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    handleEditSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .post(`update_order_costing/${this.costingId}`, this.costingForm)
            .then((response) => {
              if (response.data.status === false) {
                this.$refs.form.setErrors(response.data.errors);
                this.isLoading = false;
                return;
              }
              this.$refs["edit"].hide();
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoading = false;
                  this.$refs["edit"].hide();
                  this.getOrders();
                }
              });
            });
        }
      });
    },
  },

  data() {
    return {
      isLoading: true,
      costingForm: {},
      costingId: 0,
      selected_branch: "Puchong",
      selected_status_type: "Sales Report",
      date_end: new Date().toISOString().split("T")[0],
      date_start: new Date(Date.now() - 172800000).toISOString().split("T")[0],
      selectedDate: "",
      total_convert_risk: 0,
      total_commission: 0,
      total_gross_profit: 0,
      columns: [],
      report: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "align-middle",
        },
        {
          label: "Order Date/Time",
          field: "order_placed_date",
          tdClass: "align-middle",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup",
          tdClass: "align-middle",
        },
        {
          label: "Delivery Date/Time",
          field: "delivery",
          tdClass: "align-middle",
        },
        {
          label: "Sender's Name",
          field: "sendername",
          tdClass: "align-middle",
        },
        {
          label: "Recipient's Name",
          field: "recipientname",
          tdClass: "align-middle",
        },
        {
          label: "Origin Address",
          field: "origin",
          tdClass: "align-middle",
        },
        {
          label: "Destination Address",
          field: "destination",
          tdClass: "align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "align-middle",
        },
        {
          label: "Quantity",
          field: "quantity",
          tdClass: "align-middle",
        },
        {
          label: "Grand Total",
          field: "selling_price",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Actions",
          field: "actions",
          thClass: "text-nowrap",
          tdClass: "align-middle",
        },
      ],
      profitloss: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "align-middle",
        },
        {
          label: "Order Date/Time",
          field: "order_placed_date",
          tdClass: "align-middle",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup",
          tdClass: "align-middle",
        },
        {
          label: "Delivery Date/Time",
          field: "delivery",
          tdClass: "align-middle",
        },
        {
          label: "Origin Postcode",
          field: "origin_postcode",
          tdClass: "align-middle",
        },
        {
          label: "Destination Postcode",
          field: "destination_postcode",
          tdClass: "align-middle",
        },
        {
          label: "Quantity",
          field: "quantity",
          tdClass: "align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "align-middle",
        },
        {
          label: "Grand Total",
          field: "selling_price",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Costing",
          field: "costing",
          tdClass: "align-middle",
        },
        {
          label: "Profit",
          field: "profit",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Commission",
          field: "commission",
          tdClass: "align-middle",
        },
        {
          label: "Actions",
          field: "actions",
          thClass: "text-nowrap",
          tdClass: "align-middle",
        },
      ],
      convertrisk: [
        {
          label: "Order ID",
          field: "id",
          tdClass: "align-middle",
        },
        {
          label: "Order Date/Time",
          field: "order_placed_date",
          tdClass: "align-middle",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup",
          tdClass: "align-middle",
        },
        {
          label: "Delivery Date/Time",
          field: "delivery",
          tdClass: "align-middle",
        },
        {
          label: "Destination Address",
          field: "destination",
          tdClass: "align-middle",
        },
        {
          label: "Status",
          field: "status",
          tdClass: "align-middle",
        },
        {
          label: "Quantity",
          field: "quantity",
          tdClass: "align-middle",
        },
        {
          label: "Grand Total",
          field: "selling_price",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Convert Risk",
          field: "convert_risk",
          thClass: "text-nowrap",
          tdClass: "text-center align-middle",
        },
        {
          label: "Actions",
          field: "actions",
          thClass: "text-nowrap",
          tdClass: "align-middle",
        },
      ],
      rows: [],
      branchId: "",
      statusTypeOptions: [
        { label: "Sales Report", value: "Sales Report" },
        { label: "Profit & Loss Report", value: "Profit & Loss Report" },
        { label: "Convert Risk Report", value: "Convert Risk Report" },
      ],
      required,
    };
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.fixed-width-header-container {
  width: 180px;
}
</style>
